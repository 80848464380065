import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header";
import { HitoTest } from "./hitokoto";
import { MusicTest } from './cloudmusic';
import { ReportCharts } from './google-analytics'
import { BlogSearchTest } from "./blog-search";
import Footer from "../components/footer";
import '../components/layout.css'
import SEO from '../components/seo'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  let Test;
  switch (frontmatter.path) {
    case "/hitokoto/":
      Test = HitoTest;
      break;
    case "/cloudmusic/":
      Test = MusicTest;
      break;
    case "/gaviews/":
      Test = ReportCharts;
      break;
    case "/blog-search/":
      Test = BlogSearchTest;
      break;

    default:
      break;
  }
  return (
    <div className="site">
      <SEO title={frontmatter.title} keywords={[`gatsby`, `application`, `react`]} />
      <Header />
      <div className="site-content">
        {/* <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2> */}
        <span style={{ float: "right" }}><small>本页面更新于：{frontmatter.date}<br />API 更新于：{frontmatter.updated}</small></span>
        {/* {console.log(frontmatter)} */}
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <Test />
      </div>
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
