import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'rsuite'
const { Column, HeaderCell, Cell, Pagination } = Table

class BlogSearchTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rawQ: "hello",
            result: [{ 'url': '', 'title': '', 'snippet': '', 'date': '' }]
        }
    }
    submit = () => {
        const query = { "pages": "1-9" }
        console.log(this.state.rawQ)
        this.state.rawQ.split(" ").forEach(t => {
            if (t.startsWith("tags:") || t.startsWith("category:")) {
                if (!query.terms) query.terms = [];
                query.terms.push(t)
            } else if (t.startsWith("range:")) {
                query.range = t.slice(6)
            } else {
                if (!query.q) query.q = []
                query.q.push(t)
            }
        })
        let url = new URL(`${document.URL}v1/`)
        url.search = new URLSearchParams(query).toString()
        fetch(url)
            .then(resp => resp.json())
            .then(data => {
                this.setState((prev, props) => {
                    return {
                        total: data.count,
                        result: data.data
                    }
                })
            })
    }

    render() {
        return (
            <div>
                搜索： &nbsp;
                <input style={{ width: "80%" }} onChange={(e) => { this.setState({ rawQ: e.target.value }) }} />
                &nbsp;&nbsp;
                <button onClick={this.submit}>搜索</button>
                <p />
                结果（前 9 条）：
                <Table data={this.state.result}>
                    <Column width={200} sort fixed resizable>
                        <HeaderCell>URL</HeaderCell>
                        <Cell dataKey="url" />
                    </Column>
                    <Column width={200} sort fixed resizable>
                        <HeaderCell>标题</HeaderCell>
                        <Cell dataKey="title" />
                    </Column>
                    <Column width={200} sort fixed resizable>
                        <HeaderCell>日期</HeaderCell>
                        <Cell dataKey="date" />
                    </Column>
                    <Column width={200} sort fixed resizable>
                        <HeaderCell>摘要</HeaderCell>
                        <Cell dataKey="snippet" />
                    </Column>
                </Table>
            </div>
        )
    }
}

export { BlogSearchTest };