import React, { Component } from 'react'
import { Table } from 'rsuite'

const { Column, HeaderCell, Cell, Pagination } = Table

class ReportCharts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prefix: null,
            show: false,
            total: 0,
            result: [{ 'path': '', 'count': '' }]
        }
    }

    submit = () => {
        const prefix = this.state.prefix ? `?prefix=${this.state.prefix}` : ""
        fetch(`https://api.itswincer.com/gaviews/v1/` + prefix)
            .then(resp => resp.json())
            .then(data => {
                this.setState((prev, props) => {
                    const newState = !prev.show; return {
                        show: newState,
                        result: data.details,
                        total: data.total
                    }
                })
            })
    }

    render() {
        if (!this.props.show) {
            return <div>
                URL 前缀: &nbsp;
                <input style={{ width: "80%" }}
                    placeholder="字符串"
                    value={this.state.prefix}
                    onChange={(e) => { this.setState({ prefix: e.target.value }) }} /> &nbsp;&nbsp;
                <button onClick={this.submit}>确认</button>
                <br />
                <Table data={this.state.result}>
                    <Column width={400} sort fixed resizable>
                        <HeaderCell>URL 路径</HeaderCell>
                        <Cell dataKey="path" />
                    </Column>
                    <Column width={400} sort fixed resizable>
                        <HeaderCell>浏览数（{this.state.total}）</HeaderCell>
                        <Cell dataKey="count" />
                    </Column>
                </Table>
            </div>
        }
    }
}

export { ReportCharts };
