import React, { Component } from 'react'
import PropTypes from 'prop-types';

class Player extends Component {
    constructor(props) {
        super(props)
        this.state = { title: null }
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        this.ra = isBrowser ? require('react-aplayer').default : undefined;
    }

    render() {
        if (!this.props.show) {
            return <p>点击上方按钮后如没反应请稍等一会儿, 歌曲正在加载哦 ~</p>
        }
        return (
            <this.ra
                {...this.props}
                onInit={(ap) => { this.ap = ap }}
                onPlay={() => { console.log("on play") }}
                onPause={() => { console.log("on pause") }}
            />
        )
    }
}

Player.propTypes = {
    show: PropTypes.object.isRequired
}

class MusicTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            musicId: "498134",
            show: false,
            playinfo: {}
        }
    }

    submit = () => {
        this.setState({ show: false })
        Promise.all([fetch(`https://api.itswincer.com/cloudmusic/v1/media?id=${this.state.musicId}`),
        fetch(`https://api.itswincer.com/cloudmusic/v1/detail?ids=[${this.state.musicId}]`)])
            .then(async (resps) => {
                let [lrc, detail] = [await resps[0].text(), await resps[1].text()];
                console.log("lrc: " + JSON.parse(lrc))
                console.log("detail: " + JSON.parse(detail))
                lrc = JSON.parse(lrc); detail = JSON.parse(detail)
                if (lrc.nolyric) {
                    lrc['lyric'] = '[00:00.00]纯音乐，请欣赏\n';
                }
                detail['lrc'] = lrc.lyric;
                var blob = new Blob([detail.lrc], { type: 'application/octet-stream' })

                if (detail.songs[0] === undefined) {
                    alert("请输入合法的网易云 ID。")
                    return
                }

                await this.setState({
                    playinfo: {
                        title: detail.songs[0].name,
                        author: detail.songs[0].artists[0].name,
                        url: `https://music.163.com/song/media/outer/url?id=${this.state.musicId}.mp3`,
                        pic: detail.songs[0].album.picUrl + '?param=130y130',
                        lrc: URL.createObjectURL(blob),
                    }
                })
                this.setState((prev, props) => { const newState = !prev.show; return { show: newState } })
            })
    }

    render() {
        const props = {
            theme: '#653399',
            lrcType: 3,
            audio: [
                this.state.playinfo
            ]
        }
        return (
            <div>
                网易云歌曲 ID：&nbsp;
                <input style={{ width: "130px" }}
                    placeholder="歌曲 ID"
                    value={this.state.musicId}
                    onChange={(e) => { this.setState({ musicId: e.target.value }) }} />
                &nbsp;&nbsp;&nbsp;
                <button onClick={this.submit} >确认</button>
                <p />
                <Player {...props} show={this.state.show} />
            </div>
        )
    }
}

export { MusicTest };