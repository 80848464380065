import React, { Component } from 'react'

const encodes = ["null", "js", "json", "text"];

class HitoTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            length: null,
            callback: null,
            charset: null,
            encode: null,
            display: null
        }
    }


    submit = () => {
        const callback = this.state.callback ? `&callback=${this.state.callback}` : "";
        const length = this.state.length ? `&length=${this.state.length}` : "";
        const encode = this.state.encode ? `&encode=${this.state.encode}` : "";
        fetch(`https://api.itswincer.com/hitokoto/v2/?` +
            callback + length + encode)
            .then(resp => resp.text())
            .then(data => {
                this.setState({ display: data })
            })
    }

    render() {
        return (
            <div>
                编码格式：&nbsp;
                <select style={{ width: "65px" }}
                    onChange={(e) => { this.setState({ encode: e.target.value }) }}>
                    {
                        encodes.map((op, index) => {
                            return (
                                <option key={index} value={op}>{op}</option>
                            )
                        })
                    }
                </select> &nbsp;&nbsp;
                一言长度：&nbsp;
                <input style={{ width: "65px" }}
                    placeholder="整数"
                    value={this.state.length}
                    onChange={(e) => { this.setState({ length: e.target.value }) }}
                />&nbsp;&nbsp;&nbsp;
               回调名称：&nbsp;
                <input style={{ width: "70px" }}
                    placeholder="英文"
                    value={this.state.callback}
                    onChange={(e) => { this.setState({ callback: e.target.value }) }}
                />&nbsp;&nbsp;&nbsp;
                <button onClick={this.submit}>确认</button>

                <input style={{ marginTop: "4px" }}
                    placeholder="这里将会展示返回的数据"
                    value={this.state.display}
                />
            </div>
        )
    }
}

export { HitoTest };